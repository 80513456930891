<template>
    <div class="position-relative tailwind" @wheel.stop :style="style">
        <Map v-if="hasMap" :config="{
            provider: 'tomtom',
            map: '2/basic_street-light',
            poi: '2/poi_light',
            trafficIncidents: '2/incidents_light',
            trafficFlow: '',
        }">
            <Layer @select-vehicule="emit('select-vehicule', $event)"></Layer>
        </Map>
        <div class="trajet-map-placeholder" :style="style" v-else>
            <template v-if="data === null">
                <i class="fa-regular fa-map-location-dot fa-2x fa-fade" title="Chargement…"></i>
            </template>
            <template v-else>
                <i class="fa-regular fa-map trajet-map-placeholder-icon"></i>
                <div class="trajet-map-placeholder-title">Cartographie indisponible</div>
                <div class="trajet-map-placeholder-message">Trajet non géocodé</div>
            </template>
        </div>
        <div class="trajet-map-overlay"></div>
    </div>
</template>

<script setup>
import Map from "@/Vue/Components/RegulationV2/Map/Base/Map.vue";
import {computed, onMounted, provide, ref, toRefs, watch} from "vue";
import Layer from "@/Vue/Components/Trajet/Map/Layer.vue";
import Router from '@/App/Router';
import {useRequest} from "@/Vue/Composables/Request";

const {getJson} = useRequest();

const emit = defineEmits(['select-vehicule']);
const props = defineProps(['height', 'structures', 'id']);

const {height, structures, id} = toRefs(props);

const style = computed(() => height.value ? {height: height.value} : {height: '100%', 'min-height': '150px'});

const hasMap = computed(() => data.value && (data.value.routesGeoJSON !== null || data.value.appareils.length));

const positions = ref([]);
const data = ref(null);

provide('positions', positions);
provide('data', data);

watch(id, () => {
    data.value = null;
    getJson(Router.generate('regulation.trajet.map', {id: id.value})).then((res) => {
        data.value = res;
    });
}, {immediate: true});

onMounted(() => {
    if(structures.value) {
        App.webSocket.subscribe('shared/position', (uri, data) => {
            positions.value = data.positions;
        });
        App.webSocket.publish('shared/position', {
            structureCollection: structures.value,
        });
    }
})
</script>
