'use strict';

import Bloodhound from "bloodhound-js";
import Router from "@/App/Router";
import Moment from "moment";
import Euro from "@/Vue/Filters/Euro";
import Capitalize from '../../Vue/Filters/Capitalize';
import moment from "moment/moment";
import Vue, {createApp} from "vue";
import Cookies from "js-cookie";
import ladda from "ladda";

App.Shared.Transport = class {
    static _initExport(exportUrl, filename) {
        Cookies.remove('transportExportDownloadFinished');

        let $exportForm = $('#exportForm');
        let $exportPrintBtn = $('#exportPrintBtn');
        let $exportDownloadBtn = $('#exportDownloadBtn');

        let exportPrintBtnLadda = ladda.create($exportPrintBtn[0]);
        let exportDownloadBtnLadda = ladda.create($exportDownloadBtn[0]);

        $exportPrintBtn.click((e) => {
            let url = exportUrl + '/html?' + decodeURI($exportForm.serialize());

            if(e.ctrlKey) {
                let win = window.open(url, '_blank');
                if(win) {
                    win.focus();
                    return;
                }
            }

            if(e.shiftKey) {
                window.location.href = url;
                return;
            }

            exportPrintBtnLadda.start();
            $exportDownloadBtn.prop('disabled', true);

            App.Utils.print(url, filename).then(() => {
                exportPrintBtnLadda.stop();
                $exportDownloadBtn.prop('disabled', false);
            });
        });

        $exportForm.submit(() => {
            exportDownloadBtnLadda.start();
            $exportPrintBtn.prop('disabled', true);

            let checkFinish = () => {
                if (Cookies.get('transportExportDownloadFinished')) {
                    exportDownloadBtnLadda.stop();
                    $exportPrintBtn.prop('disabled', false);
                    Cookies.remove('transportExportDownloadFinished');
                } else {
                    setTimeout(checkFinish, 1000);
                }
            };

            checkFinish();
        });
    }
};

App.Shared.Transport.Form = class {
    constructor(params) {
        moment.locale('fr');

        this.app = params.app;
        this.form = params.form;
        this.paths = params.paths;
        this.constants = params.constants;
        this.id = params.id;
        this.templates = params.templates;

        this._initAutocomplete();
        this._initRefreshPatient();
        this._initPanels();
        this._initTrajets();
        this._initAppelant();
        this._initPrescripteur();
        this._initPrescriptionEnLigne();
        this._initExoneration();
        this._initPecPlus();
        this._initIdentiteTemporaire();
        this._initButtons();
        this._initDateField();
        this._initSefi();

        App.Shared.initTrajetTops();
        App.Shared.initTrajetTarification();
        App.Shared.initTypeFacture(this.form.prescripteurForm);

        this._initAjax();
    }

    _initSefi() {
        const $trajetRetourToggle = $('#' + this.form.trajetRetour.enabled);

        $('.js-sefi-trajet-unlink').click((e) => {
            $(e.currentTarget).parents('.panel').find('[data-field="sefiId"]').val('').change();

            e.preventDefault();
        });

        $('[data-field="sefiId"]').change((e) => {
            const $el = $(e.currentTarget);
            const $badge = $el.parents('.panel').find('.js-sefi-trajet-badge');
            const toggle = $el.val() !== '';

            if(toggle && $el.data('type') === 'trajetRetour' && !$trajetRetourToggle.is(':checked')) {
                $trajetRetourToggle.prop('checked', true).change();
            }

            $badge.toggleClass('d-flex', toggle).toggleClass('d-none', !toggle);
            $badge.find('.js-sefi-trajet-id').text($el.val());
        });

        $('#transport_numeroPrescription').on('input', () => {
            $('[data-field="sefiId"]').val('').change();
        });
    }

    _updateDateField($fields = null) {
        const now = Moment().startOf('day');
        const limite = Moment().subtract(1, 'hour');
        const isNew = null === this.id;

        ($fields || $('[data-field="rendezVousDate"]')).each(function() {
            const date = Moment($(this).val(), 'DD/MM/YYYY');
            const $badge = $(this).parents('.js-trajet-embed').find('.js-trajet-rdv');
            const $badgeWarning = $(this).parents('.js-trajet-embed').find('.js-trajet-rdv-warning');
            const $badgeDay = $(this).parents('.js-trajet-embed').find('.js-trajet-rdv-day');

            if(date.isValid()) {
                $badge.addClass('d-inline-block');
                $badgeDay.addClass('d-inline-block').text(date.format('dddd'));

                const dayDiff = date.diff(now, 'days');
                const dayDiffAbs = Math.abs(date.diff(now, 'days'));
                const weekDiffAbs = Math.abs(date.diff(now, 'weeks'));
                const monthDiffAbs = Math.abs(date.diff(now, 'months'));
                const yearDiffAbs = Math.abs(date.diff(now, 'years'));

                let textDiff = '';

                if(yearDiffAbs) {
                    textDiff = yearDiffAbs === 1 ? '1 an' : yearDiffAbs+ ' ans';
                } else if(monthDiffAbs) {
                    textDiff = monthDiffAbs+ ' mois';
                } else if(weekDiffAbs) {
                    textDiff = weekDiffAbs === 1 ? '1 semaine' : weekDiffAbs+ ' semaines';
                } else {
                    textDiff = dayDiffAbs+ ' jours';
                }

                if(0 === dayDiff) {
                    $badge.removeClass('badge-secondary').addClass('badge-info').removeClass('badge-primary').text('Aujourd\'hui');
                }
                else if(dayDiff > 0) {
                    $badge.removeClass('badge-secondary').removeClass('badge-info').addClass('badge-primary').text(1 === dayDiff ? 'Demain' : 'Dans '+textDiff);
                } else {
                    $badge.addClass('badge-secondary').removeClass('badge-info').removeClass('badge-primary').text(-1 === dayDiff ? 'Hier' : 'Il y a  '+textDiff);
                }

                let showWarning = false;

                if(isNew) {
                    const $rendezVousTime = $(this).parents('.js-trajet-embed').find('[data-field="rendezVousTime"]');
                    if($rendezVousTime.val()) {
                        const dateTime = Moment($(this).val() + ' ' + $rendezVousTime.val(), 'DD/MM/YYYY HH:mm');
                        showWarning = dateTime.isValid() && dateTime.isBefore(limite);
                    }
                }

                $badgeWarning.toggleClass('d-inline-block', showWarning);
            } else {
                $badge.removeClass('d-inline-block');
                $badgeWarning.removeClass('d-inline-block');
                $badgeDay.removeClass('d-inline-block');
            }
        });
    }

    _initDateField() {
        if (null === this.id) {
            const $rendezVousDateAller = $('#transport_trajetAller_rendezVous_date');
            if (!$rendezVousDateAller.val()) {
                const defaultDate = (new URLSearchParams(window.location.search)).get('date') || Moment().format('DD/MM/YYYY');
                $rendezVousDateAller.val(defaultDate);
            }
        }

        $('body').on('change', '[data-field="rendezVousDate"], [data-field="rendezVousTime"]', (e) => this._updateDateField($(e.currentTarget).parents('.js-trajet-embed').find('[data-field="rendezVousDate"]')));

        this._updateDateField();
    }

    _initIdentiteTemporaire() {
        let $identiteTemporaireFields = $('#identiteTemporairePanel [data-target-field]');

        $('.js-reporter-identite-temporaire').click(() => {
            $('#' + this.form.patientBeneficiaire.enabled).prop('checked', false).change();
            $('#' + this.form.patientAssure.enabled).prop('checked', true).change();

            this._unlinkPatient('assure');
            App.Utils.clearAllInputsSelector('#assurePanel');

            $identiteTemporaireFields.each(function() {
                let $field = $('#' + $(this).data('targetField'));
                if($field.hasClass('tt-input')) {
                    $field.typeahead('val', $(this).val());
                } else if ('transport_patientAssure_entity_genre' === $field.attr('id')) {
                    let $input;

                    switch ($(this).val()) {
                        case 'Homme':
                            $input = $('[data-genre-id="' + App.Constants.GENRE.HOMME + '"]');
                            break;
                        case 'Femme':
                            $input = $('[data-genre-id="' + App.Constants.GENRE.FEMME + '"]');
                            break;
                        default:
                            $input = $('#transport_patientAssure_entity_genre_placeholder');
                            break;
                    }

                    $input.click();
                } else {
                    $field.val($(this).val());
                }
            });

            $('#' + this.form.patientAssure.nomUsuel).focus();

            $('#form').change();
        });
    }

    _initPecPlus() {
        let pecPlus = new App.PecPlus(this.paths.pecPlusAjax);

        $('#pecPlusBtn').click(() => {
            pecPlus.call($('#form').serialize());
        });
    }

    /**
     * Initialise la mise à jour automatique du formulaire.
     */
    _initAjax() {
        this.formAjaxTimeout = null;
        this.formAjaxRequest = null;
        this.discardedAutoDetectedContratId = null;
        this.autoDetectedContratId = null;

        $('body').on('change', '#form', () => {
            if(this.formAjaxTimeout) {
                clearTimeout(this.formAjaxTimeout);
            }

            this.formAjaxTimeout = setTimeout(() => {
                this._updateAjax();
                this.formAjaxTimeout = null;
            }, 10);
        });

        $('[data-field="contrat"]').change((e) => {
            const $el = $(e.currentTarget);

            if(!$el.val()) {
                this.discardedAutoDetectedContratId = this.autoDetectedContratId;
            }
        });

        if (this.id) {
            this._updateAjax();
        }
    }

    /**
     * Effectue la mise à jour des erreurs / warnings.
     */
    _updateAjax() {
        let $form = $('#form');
        $('body').addClass('cursor-progress');

        if(this.formAjaxRequest) {
            this.formAjaxRequest.abort();
            this.formAjaxRequest = null;
        }

        this.formAjaxRequest = $.ajax({
            url: this.paths.ajaxForm,
            method: 'POST',
            data: $form.serialize()
        }).always(function() {
            $('body').removeClass('cursor-progress');
        }).done((data) => {
            App.Utils.handleFormErrors($form, data.errors);

            if(data.contrat) {
                const $contrat = $('[data-field="contrat"]');

                if(!$contrat.val() && (null === this.discardedAutoDetectedContratId || this.discardedAutoDetectedContratId !== data.contrat.id)) {
                    const option = new Option(data.contrat.text, data.contrat.id, false, false);
                    $contrat.html(option);
                    this.autoDetectedContratId = data.contrat.id;

                    App.Utils.flashTooltip($contrat.parent(), 'Contrat auto détecté !');
                }
            }

            for(const trajetField of ['trajetAller', 'trajetRetour']) {
                const trajetData = data[trajetField];

                if(trajetData) {
                    if (trajetData.typeForfait) {
                        const $typeForfait = $('#transport_' + trajetField + '_typeForfait');
                        $typeForfait.find('.btn.active').removeClass('active');
                        $typeForfait.find('input[value="' + trajetData.typeForfait + '"]').prop('checked', true).parent().addClass('active');
                    }
                    if(trajetData.estimationMontantTouteTaxe !== null) {
                        $('#transport_'+trajetField+'_estimationMontantTouteTaxe').show().find('span').text(Euro(trajetData.estimationMontantTouteTaxe));
                    } else {
                        $('#transport_'+trajetField+'_estimationMontantTouteTaxe').hide();
                    }

                    App.Shared.handleTrajetTarificationGrilleDetail($('#'+trajetField+'Panel'), trajetData.grilleDetail);
                }
            }
        }).fail(function(e) {
        });
    }

    _initTrajets() {
        // Focus du champ complément à la sélection d'une adresse
        for(let trajet of ['trajetAller', 'trajetRetour']) {
            for(let type of ['PriseEnCharge', 'Destination']) {
                $('#' + this.form[trajet]['adresse'+type]).on('typeahead:select', () => {
                    $('#' + this.form[trajet]['complementAdresse'+type]).focus();
                });
            }
        }
    }

    _initAppelant() {
        App.Shared.initAppelant(this.paths.ajaxAppelant);
    }

    _initPrescripteur() {
        App.Shared.initPrescripteur(this.paths.ajaxPrescripteur, this.form.prescripteurLink, this.form.prescripteurForm, this.templates.placeholder.prescripteurLibre, this.templates.placeholder.prescripteur);
    }

    _initPrescriptionEnLigne() {
        $('#transport_numeroPrescription').on('input', function() {
            $('#transport_prescription').prop('readonly', false).next().find('.js-datetimebutton').prop('disabled', false);
            $(this).removeClass('border-theme');
            $('#transport_prescriptionEnLigneData').val('null');
            $("#transport_motif").removeClass('js-select2-readonly');
            $('[data-field="sefiId"]').val('').change();
        });
    }

    _initPanels() {
        // Panel patient
        let $assureToggle = $('#' + this.form.patientAssure.enabled);
        let $beneficiaireToggle = $('#' + this.form.patientBeneficiaire.enabled);
        let $beneficiaireTitle = $('#beneficiaireTitle');
        let $assureClose = $('#assureClose');
        let $beneficiaireClose = $('#beneficiaireClose');
        let $beneficiairePlaceholder = $('#beneficiairePlaceholder');
        let $patientBeneficiairePanelButtons = $('#beneficiairePanelButtons');
        let $patientAssurePlaceholder = $('#patientAssurePlaceholder');
        let $patientAssurePanelButtons = $('#assurePanelButtons');
        let $assurePanel = $('#assurePanel');
        let $beneficiairePanel = $('#beneficiairePanel');

        $('#identiteTemporairePanel').on('click', '.panel-heading', function (e) {
            if(this === e.target) {
                $(this).siblings('.collapse').collapse('toggle');
            }
        });

        $assureClose.click(function () {
            $assureToggle.prop('checked', false).change();
            return false;
        });

        $beneficiaireClose.click(function () {
            $beneficiaireToggle.prop('checked', false).change();
            return false;
        });

        $beneficiaireToggle.change((e) => {
            if($(e.currentTarget).prop('checked')) {
                $beneficiaireTitle.hide();
                $beneficiairePlaceholder.hide();
                $beneficiairePanel.show();
                $beneficiairePanel.find('[data-required=true]').prop('required', true);

                $('#' + this.form.patientBeneficiaire.rangNaissance).val('1');

                let nom = $('#' + this.form.patientAssure.nomUsuel).val();
                if(nom) {
                    $('#' + this.form.patientBeneficiaire.nomUsuel).typeahead('val', nom);
                    $('#' + this.form.patientBeneficiaire.prenomUsuel).focus();
                } else {
                    $('#' + this.form.patientBeneficiaire.nomUsuel).focus();
                }
            } else {
                $beneficiaireTitle.show();
                $beneficiairePlaceholder.show();
                $beneficiairePanel.find('[required]').prop('required', false);
                $beneficiairePanel.hide();

                App.Utils.clearAllInputsSelector('#beneficiairePanel');
                this._unlinkPatient('beneficiaire');
            }
        });

        $assureToggle.change((e) => {
            if($(e.currentTarget).prop('checked')) {
                $('#' + this.form.patientAssure.rangNaissance).val('1');

                $patientAssurePlaceholder.hide();
                $assurePanel.find('[data-required=true]').prop('required', true);
                $assurePanel.show();
                $beneficiairePlaceholder.show();
            } else {
                $patientAssurePlaceholder.show();
                $assurePanel.find('[required]').prop('required', false);
                $assurePanel.hide();

                App.Utils.clearAllInputsSelector('#assurePanel');
                this._unlinkPatient('assure');

                $beneficiaireToggle.prop('checked', false).change();
                $beneficiairePlaceholder.hide();
            }
        });

        $patientAssurePanelButtons.find('[data-clear-fields]').click(() => {
            this._unlinkPatient('assure');
            this._unlinkPatient('beneficiaire');
        });

        $patientBeneficiairePanelButtons.find('[data-clear-fields]').click(() => {
            this._unlinkPatient('beneficiaire');
        });

        // Checkbox annulé trajets
        for(let field of ['trajetAller', 'trajetRetour']) {
            if(this.form[field].estAnnule) {
                $('#' + this.form[field].estAnnule).change((e) => {
                    $('#' + this.form[field].nePasFacturer).prop('checked', $(e.currentTarget).is(':checked')).change();
                });
            }
        }

        // Panel trajet retour
        let $trajetRetourToggle = $('#' + this.form.trajetRetour.enabled);
        let $trajetRetourPanel = $('#trajetRetourPanel');
        let $trajetRetourClose = $('#trajetRetourClose');
        let $trajetRetourActions = $('#trajetRetourActions');
        let $trajetRetourPlaceholder = $('#trajetRetourPlaceholder');

        $trajetRetourClose.click(function () {
            $trajetRetourToggle.prop('checked', false).change();
            return false;
        });

        $trajetRetourToggle.change((e) => {
            if($(e.currentTarget).prop('checked')) {
                let fields = {
                    [this.form.trajetRetour.rendezVous.date]: this.form.trajetAller.rendezVous.date,
                    [this.form.trajetRetour.kilometre]: this.form.trajetAller.kilometre,

                    [this.form.trajetRetour.latitudeDegrePriseEnCharge]: this.form.trajetAller.latitudeDegreDestination,
                    [this.form.trajetRetour.longitudeDegrePriseEnCharge]: this.form.trajetAller.longitudeDegreDestination,
                    [this.form.trajetRetour.codeInseePriseEnCharge]: this.form.trajetAller.codeInseeDestination,
                    [this.form.trajetRetour.complementAdressePriseEnCharge]: this.form.trajetAller.complementAdresseDestination,
                    [this.form.trajetRetour.precisionPriseEnCharge]: this.form.trajetAller.precisionDestination,
                    [this.form.trajetRetour.digicodePriseEnCharge]: this.form.trajetAller.digicodeDestination,
                    [this.form.trajetRetour.etagePriseEnCharge]: this.form.trajetAller.etageDestination,

                    [this.form.trajetRetour.latitudeDegreDestination]: this.form.trajetAller.latitudeDegrePriseEnCharge,
                    [this.form.trajetRetour.longitudeDegreDestination]: this.form.trajetAller.longitudeDegrePriseEnCharge,
                    [this.form.trajetRetour.codeInseeDestination]: this.form.trajetAller.codeInseePriseEnCharge,
                    [this.form.trajetRetour.complementAdresseDestination]: this.form.trajetAller.complementAdressePriseEnCharge,
                    [this.form.trajetRetour.precisionDestination]: this.form.trajetAller.precisionPriseEnCharge,
                    [this.form.trajetRetour.digicodeDestination]: this.form.trajetAller.digicodePriseEnCharge,
                    [this.form.trajetRetour.etageDestination]: this.form.trajetAller.etagePriseEnCharge
                };

                let typeaheadFields = {
                    [this.form.trajetRetour.adressePriseEnCharge]: this.form.trajetAller.adresseDestination,
                    [this.form.trajetRetour.codePostalPriseEnCharge]: this.form.trajetAller.codePostalDestination,
                    [this.form.trajetRetour.villePriseEnCharge]: this.form.trajetAller.villeDestination,

                    [this.form.trajetRetour.adresseDestination]: this.form.trajetAller.adressePriseEnCharge,
                    [this.form.trajetRetour.codePostalDestination]: this.form.trajetAller.codePostalPriseEnCharge,
                    [this.form.trajetRetour.villeDestination]: this.form.trajetAller.villePriseEnCharge
                };

                for(let field in fields) {
                    $('#'+field).val($('#'+fields[field]).val());
                }

                for(let field in typeaheadFields) {
                    $('#'+field).typeahead('val', $('#'+typeaheadFields[field]).val());
                }

                const motifId = $("#transport_motif").val();
                const reglesTrajetRetour = JSON.parse(App.Shared.getStructureElement().data('regle-trajet-retour'));
                const regleTrajetRetour = reglesTrajetRetour[motifId] || reglesTrajetRetour.default || {heure: '23:59'};

                let heure = '';

                if (regleTrajetRetour) {
                    if (regleTrajetRetour.delai !== null) {
                        const rdvAllerTime =  $('#' + this.form.trajetAller.rendezVous.time).val();
                        const rdvAllerDate =  $('#' + this.form.trajetAller.rendezVous.date).val();

                        if (rdvAllerTime && rdvAllerDate) {
                            const rdv = moment(rdvAllerDate + ' ' + rdvAllerTime, 'DD/MM/YYYY HH:mm');

                            if (rdv.isValid()) {
                                rdv.add(regleTrajetRetour.delai, 'minutes');
                                heure = rdv.format('HH:mm');
                                $('#' + this.form.trajetRetour.rendezVous.date).val(rdv.format('DD/MM/YYYY'));
                            }
                        }
                    } else if (regleTrajetRetour.heure !== null) {
                        heure = regleTrajetRetour.heure;
                    }
                }

                $('#' + this.form.trajetRetour.rendezVous.time).val(heure);
                $('#' + this.form.trajetRetour.topPriseEnChargeArrivee).val(heure);
                $('#' + this.form.trajetRetour.modeRendezVous+'_0').prop('checked', true);

                $('#'+this.form.trajetRetour.precisionPriseEnCharge).change();
                $('#'+this.form.trajetRetour.precisionDestination).change();
                App.Layout.triggerDistancier(this.form.trajetRetour.codeInseeDestination);
                this._updateDateField();

                App.Shared.updateTrajetTarification($trajetRetourPanel);

                $trajetRetourPanel.find('[data-required=true]').prop('required', true);
                $trajetRetourPanel.show();
                $trajetRetourPlaceholder.hide();
            } else {
                App.Utils.clearAllInputsSelector('#trajetRetourPanel');
                $trajetRetourPanel.find('[required]').prop('required', false);
                $trajetRetourPanel.find('[data-field="sefiId"]').change();
                $trajetRetourActions.find('input').prop('checked', false);

                $trajetRetourPanel.hide();
                $trajetRetourPlaceholder.show();
            }
        });

        // Gestion des champs requis des embed optionnels
        let $trajetRetourRequiredInputs = $('#trajetRetourPanel [required="required"]').attr('data-required', true);
        let $beneficiaireRequiredInputs = $('#beneficiairePanel [required="required"]').attr('data-required', true);
        let $assureRequiredInputs = $('#assurePanel [required="required"]').attr('data-required', true);

        if(!$trajetRetourToggle.prop('checked')) {
            $trajetRetourRequiredInputs.removeAttr('required');
        }
        if(!$beneficiaireToggle.prop('checked')) {
            $beneficiaireRequiredInputs.removeAttr('required');
        }
        if(!$assureToggle.prop('checked')) {
            $assureRequiredInputs.removeAttr('required');
        }
    }

    _initExoneration() {
        for(const patientField of ['patientAssure', 'patientBeneficiaire']) {
            App.Shared.initExoneration(
                this.form[patientField].entity+'_typeExonerationBtn',
                this.form[patientField].nature,
                this.form[patientField].exoneration,
                this.form[patientField].caisse,
                this.form[patientField].tauxCaisse,
                this.form[patientField].mutuelle,
                this.form[patientField].tauxMutuelle,
            );
        }
    }

    _fillPatient(data, fromBeneficiaire = false) {
        let $patientAssureHidden = $('#' + this.form.patientAssureLinkForm);
        let $patientAssureMetrics = $('#patientAssureMetrics');
        let $patientAssureIconLink = $('#patientAssureIconLink');
        let $patientAssureIconNew = $('#patientAssureIconNew');

        let $patientBeneficiaireHidden = $('#' + this.form.patientBeneficiaireLinkForm);
        let $patientBeneficiaireIconLink = $('#patientBeneficiaireIconLink');
        let $patientBeneficiaireIconNew = $('#patientBeneficiaireIconNew');

        for (let name in this.patientAutocompleteInputs[data.type]) {
            let $input = this.patientAutocompleteInputs[data.type][name];
            let value = data[name];

            if (value) {
                $input.addClass('border-theme');
            } else {
                $input.removeClass('border-theme');
            }

            $input.data('empty', value === null);
            $input.typeahead('val', value);
        }

        let viewPatientUrl = this.paths.viewPatient.replace('_ID_', data.id);

        $('#' + this.form.nature).val(data.nature);
        $('#' + this.form.exoneration).val(data.exoneration);

        if (data.type === 'beneficiaire') {
            $patientBeneficiaireHidden.val(data.id).change();
            $patientBeneficiaireIconLink.show();
            $patientBeneficiaireIconLink.attr('href', viewPatientUrl);
            $patientBeneficiaireIconNew.hide();
            $('[data-refresh-patient="beneficiaire"]').show();

            let $mutuelle = $('#' + this.form.patientBeneficiaire.mutuelle);
            if (data.mutuelle) {
                $mutuelle.html(new Option(data.mutuelle.text, data.mutuelle.id, false, false));
            } else {
                $mutuelle.val(null);
            }
            $mutuelle.trigger('change.select2');

            $('#' + this.form.patientBeneficiaire.adresse).typeahead('val', data.adresse);
            $('#' + this.form.patientBeneficiaire.complementAdresse).val(data.complementAdresse);
            $('#' + this.form.patientBeneficiaire.codeInsee).val(data.codeInsee);
            $('#' + this.form.patientBeneficiaire.codePostal).typeahead('val', data.codePostal);
            $('#' + this.form.patientBeneficiaire.digicode).val(data.digicode);
            $('#' + this.form.patientBeneficiaire.email).val(data.email);
            $('#' + this.form.patientBeneficiaire.etage).val(data.etage);
            $('[name="' + this.form.patientBeneficiaire.genre + '"][value="' + (null === data.genre ? '' : data.genre) + '"]').prop('checked', true).trigger('click');
            $('#' + this.form.patientBeneficiaire.latitudeDegre).val(data.latitudeDegre);
            $('#' + this.form.patientBeneficiaire.lien).val(data.lien).trigger('change.select2');
            $('#' + this.form.patientBeneficiaire.longitudeDegre).val(data.longitudeDegre);
            $('#' + this.form.patientBeneficiaire.naissance).val(data.naissance);
            $('#' + this.form.patientBeneficiaire.note).val(data.note);
            $('#' + this.form.patientBeneficiaire.notePublique).val(data.notePublique);
            $('#' + this.form.patientBeneficiaire.precision).val(data.precision).change();
            $('#' + this.form.patientBeneficiaire.rangNaissance).val(data.rangNaissance);
            $('#' + this.form.patientBeneficiaire.tauxCaisse).val(data.tauxCaisse);
            $('#' + this.form.patientBeneficiaire.tauxMutuelle).val(data.tauxMutuelle);
            $('#' + this.form.patientBeneficiaire.nature).val(data.nature);
            $('#' + this.form.patientBeneficiaire.exoneration).val(data.exoneration);

            for (const i of [0,1]) {
                const telephone = data.telephoneCollection[i];
                $('#' + this.form.patientBeneficiaire['telephone' + (i+1) + 'Libelle']).val(telephone ? telephone.libelle : '');
                $('#' + this.form.patientBeneficiaire['telephone' + (i+1) + 'Telephone']).val(telephone ? telephone.telephone : '');
                $('#' + this.form.patientBeneficiaire['telephone' + (i+1) + 'EnvoyerSms']).prop('checked', telephone ? telephone.envoyerSms : false).parent().toggleClass('active', telephone ? telephone.envoyerSms : false);
            }
            $('#' + this.form.patientBeneficiaire.ville).typeahead('val', data.ville);

            let $formPatientBeneficiaireEnabled = $('#' + this.form.patientBeneficiaire.enabled);

            if (!$formPatientBeneficiaireEnabled.prop('checked')) {
                $formPatientBeneficiaireEnabled.prop('checked', true).change();
            }

            if (data.assure && data.assure.id !== $patientAssureHidden.val()) {
                this._fillPatient(data.assure, true);
            }
        }
        else if (data.type === 'assure') {
            $patientAssureHidden.val(data.id).change();
            $patientAssureMetrics.html('Dû : <span class="font-weight-bold" title="'+data._metrics.count+' facture(s) non soldées">'+ App.String.euro(data._metrics.montant)+'</span>');
            $patientAssureIconLink.show();
            $patientAssureIconLink.attr('href', viewPatientUrl);
            $patientAssureIconNew.hide();
            $('[data-refresh-patient="assure"]').show();

            if (data.id === $patientBeneficiaireHidden.val()) {
                $('#' + this.form.patientBeneficiaire.enabled).prop('checked', false).change();
            }

            let $caisse = $('#' + this.form.patientAssure.caisse);
            if (data.caisse) {
                $caisse.html(new Option(data.caisse.text, data.caisse.id, false, false));
            } else {
                $caisse.val(null);
            }
            $caisse.trigger('change.select2');

            let $mutuelle = $('#' + this.form.patientAssure.mutuelle);
            if (data.mutuelle) {
                $mutuelle.html(new Option(data.mutuelle.text, data.mutuelle.id, false, false));
            } else {
                $mutuelle.val(null);
            }
            $mutuelle.trigger('change.select2');

            $('#' + this.form.patientAssure.adresse).typeahead('val', data.adresse);
            $('#' + this.form.patientAssure.complementAdresse).val(data.complementAdresse);
            $('#' + this.form.patientAssure.codeInsee).val(data.codeInsee);
            $('#' + this.form.patientAssure.codePostal).typeahead('val', data.codePostal);
            $('#' + this.form.patientAssure.digicode).val(data.digicode);
            $('#' + this.form.patientAssure.email).val(data.email);
            $('#' + this.form.patientAssure.etage).val(data.etage);
            $('[name="' + this.form.patientAssure.genre + '"][value="' + (null === data.genre ? '' : data.genre) + '"]').prop('checked', true).trigger('click');
            $('#' + this.form.patientAssure.latitudeDegre).val(data.latitudeDegre);
            $('#' + this.form.patientAssure.longitudeDegre).val(data.longitudeDegre);
            $('#' + this.form.patientAssure.naissance).val(data.naissance);
            $('#' + this.form.patientAssure.note).val(data.note);
            $('#' + this.form.patientAssure.notePublique).val(data.notePublique);
            $('#' + this.form.patientAssure.precision).val(data.precision).change();
            $('#' + this.form.patientAssure.rangNaissance).val(data.rangNaissance);
            $('#' + this.form.patientAssure.tauxCaisse).val(data.tauxCaisse);
            $('#' + this.form.patientAssure.tauxMutuelle).val(data.tauxMutuelle);
            $('#' + this.form.patientAssure.nature).val(data.nature);
            $('#' + this.form.patientAssure.exoneration).val(data.exoneration);
            for (const i of [0,1]) {
                const telephone = data.telephoneCollection[i];
                $('#' + this.form.patientAssure['telephone' + (i+1) + 'Libelle']).val(telephone ? telephone.libelle : '');
                $('#' + this.form.patientAssure['telephone' + (i+1) + 'Telephone']).val(telephone ? telephone.telephone : '');
                $('#' + this.form.patientAssure['telephone' + (i+1) + 'EnvoyerSms']).prop('checked', telephone ? telephone.envoyerSms : false).parent().toggleClass('active', telephone ? telephone.envoyerSms : false);
            }
            $('#' + this.form.patientAssure.ville).typeahead('val', data.ville);

            if(!fromBeneficiaire) {
                this._unlinkPatient('beneficiaire');
            }
        }

        $('.js-telephone').each((index, value) => App.Layout.doRappelButtonLogic(value) );
    }

    _unlinkPatient(type) {
        const typeCap = Capitalize(type);

        for(let name in this.patientAutocompleteInputs[type]) {
            let $input = this.patientAutocompleteInputs[type][name];
            let value = $input.val();

            $input.typeahead('val', '');
            $input.typeahead('val', value);
            $input.removeClass('border-theme');
        }

        $('[data-refresh-patient="'+type+'"]').hide();

        const $patientLinkForm = $('#' + this.form['patient'+typeCap+'LinkForm']);

        if($patientLinkForm.val() && $('#' + this.form['patient'+typeCap].enabled).is(':checked')) {
            App.Utils.flashTooltip($('#patient'+typeCap+'Icon'), 'Un nouveau patient sera créé');
        }

        $patientLinkForm.val('');

        $('#patient'+typeCap+'Metrics').html('');
        $('#patient'+typeCap+'IconLink').hide();
        $('#patient'+typeCap+'IconNew').show();
    }

    fillPatientById(id) {
        return new Promise((resolve, reject) => {
            $.get(Router.generate('shared.transport.refresh-patient.ajax', {app: this.app, type: 'assure', patient: id})).done((data) => {
                this._fillPatient(data, true);
                resolve();
            });
        });
    }

    _initRefreshPatient() {
        $('[data-refresh-patient]').click((e) => {
            const $el = $(e.currentTarget);
            const type = $el.data('refreshPatient');
            const patient = $('#' + (type === 'assure' ? this.form.patientAssureLinkForm : this.form.patientBeneficiaireLinkForm)).val();

            if(patient) {
                $.get(Router.generate('shared.transport.refresh-patient.ajax', {app: this.app, type, patient})).done((data) => {
                    this._fillPatient(data, true);
                });
            }
            return false;
        });
    }

    _initAutocomplete() {
        // Autocomplétion des patients
        this.patientAutocompleteInputs = {
            assure: {
                numeroSecuriteSociale: $('#' + this.form.patientAssure.numeroSecuriteSociale),
                prenomUsuel: $('#' + this.form.patientAssure.prenomUsuel),
                nomUsuel: $('#' + this.form.patientAssure.nomUsuel)
            },
            beneficiaire: {
                numeroSecuriteSociale: $('#' + this.form.patientBeneficiaire.numeroSecuriteSociale),
                prenomUsuel: $('#' + this.form.patientBeneficiaire.prenomUsuel),
                nomUsuel: $('#' + this.form.patientBeneficiaire.nomUsuel)
            }
        };

        let $patientAssureHidden = $('#' + this.form.patientAssureLinkForm);

        for(let typePatient in this.patientAutocompleteInputs) {
            for(let name in this.patientAutocompleteInputs[typePatient]) {
                let engine = new Bloodhound({
                    datumTokenizer: Bloodhound.tokenizers.whitespace,
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    remote: {
                        url: this.paths.ajaxPatient,
                        prepare: (query, settings) => {
                            let idAssure = typePatient === 'beneficiaire' ? $patientAssureHidden.val() : '';
                            let $structureElement = $('[name="' + this.form.structureName + '"]');
                            let idStructure = 1 === $structureElement.length && $structureElement.hasClass('js-select2-autocomplete') ? $structureElement.val() : $('input[name="' + this.form.structureName + '"]:checked').val();

                            if (name === 'prenomUsuel') {
                                const nom = $('#' + this.form['patient'+Capitalize(typePatient)].nomUsuel).val();

                                if ('' === query && '' === nom) {
                                    settings.url = null;
                                    return settings;
                                }

                                if (!idAssure && nom !== '') {
                                    query = nom + ' ' + query;
                                }
                            }

                            settings.url = settings.url.replace('_QUERY_', encodeURIComponent(query !== '' ? query : '*')).replace('_TYPE_', typePatient).replace('_ID_ASSURE_', idAssure).replace('_ID_STRUCTURE_', idStructure);
                            return settings;
                        },
                        cache: false
                    }
                });

                this.patientAutocompleteInputs[typePatient][name].typeahead(
                    {
                        minLength: 'prenomUsuel' === name ? 0 : 2,
                        highlight: true,
                        hint: false,
                        tabAutocomplete: false,
                    },
                    {
                        source: engine,
                        limit: 400,
                        templates: {
                            suggestion: App.Utils.getAutocompleteTemplate('patient', true),
                            pending: () => '<div class="tt-suggestion text-muted">Recherche en cours…</div>',
                            notFound: '<div></div>',
                        },
                        displayKey: name
                    }
                ).on('typeahead:select', (e, data) => {
                    this._fillPatient(data);
                }).on('input', (e) => {
                    let $el = $(e.currentTarget);

                    // Traitement du numéro de sécurité sociale pour remplir certains champs automatiquement
                    if(name === 'numeroSecuriteSociale') {
                        let res;

                        if ((res = /^(\d)/.exec($el.val())) !== null) {
                            let sexe = parseInt(res[1]);
                            let sexeVal = sexe === 1  || sexe === 3 || sexe === 5 || sexe === 7 ? this.constants.ID_HOMME : sexe === 2 || sexe === 4 || sexe === 6 || sexe === 8 ? this.constants.ID_FEMME : null;

                            if(sexeVal) {
                                let $input = $('[name="' + this.form['patient'+typePatient[0].toUpperCase() + typePatient.slice(1)].genre + '"][value="' + sexeVal + '"]').prop('checked', true);
                                $input.parent().parent().find('.btn').removeClass('active');
                                $input.parent().addClass('active');
                            }
                        }
                    }

                    if(!$el.data('empty')) {
                        this._unlinkPatient(typePatient);
                        if(typePatient === 'assure') this._unlinkPatient('beneficiaire');
                    }
                }).focus(function () {
                    if(!$(this).val()) {
                        $(this).val('*').trigger('input').val('').trigger('input');
                    }
                });
            }
        }

        // Gestion du changement de stucture
        App.Shared.initTransportStructure(this.form.structureName, this.form.prescripteurForm, this.form.prescripteurLink, () => {
            // Patients
            for(let typePatient in this.patientAutocompleteInputs) {
                this._unlinkPatient(typePatient);
            }
        });
        App.Shared.updateCategoriePreset();

        // Autocomplete contrats / série
        for(const field of ['contrat', 'serie']) {
            $('[data-field="'+field+'"]').on('select2:select', (e) => {
                let data = e.params.data;
                let assureEdit = false;

                if(data.fields.assure) {
                    this._fillPatient(data.fields.assure);
                    assureEdit = true;
                }

                if(data.fields.beneficiaire) {
                    this._fillPatient(data.fields.beneficiaire);
                } else if (assureEdit) {
                    $('#beneficiaireClose').click();
                }
            });
        }
    }

    _initButtons() {
        // Boutons via michelin
        App.Shared.initDistancierBtn();

        // Boutons action trajets
        App.Shared.initTrajetAdresseBtn();

        $('.js-trajet-adresse-btn').click((e) => {
            const $el = $(e.currentTarget);
            const source = $el.data('source');
            const target = $el.data('target');

            if (['assureEmbed', 'patientEmbed', 'trajetAller'].includes(source)) {
                let fields = ['adresse', 'complementAdresse', 'codePostal', 'ville', 'digicode', 'etage', 'codeInsee', 'latitudeDegre', 'longitudeDegre', 'precision'];
                let suffix = '';
                let form;

                if('patientEmbed' === source) {
                    form = $('#' + this.form.patientBeneficiaire.enabled).is(':checked') ? this.form.patientBeneficiaire : this.form.patientAssure;
                } else if('assureEmbed' === source) {
                    form = this.form.patientAssure;
                } else {
                    form = this.form[source];
                }

                if('trajetAller' === source) {
                    suffix = 'PriseEnCharge';
                }

                for (let field of fields) {
                    const id = form[field+suffix];

                    let $field = $('#' + target.replace('adresse', field));
                    if ($field.length) {
                        const $id = $('#' + id);
                        if ($field.hasClass('tt-input')) {
                            $field.typeahead('val', $id.val());
                        } else {
                            $field.val($id.val());
                        }

                        App.Utils.flashField($field);
                        App.Utils.flashField($id, 'info');
                    }
                }

                $('#'+target.replace('adresse', 'precision')).change();
                App.Layout.triggerDistancier(target.replace('adresse', 'codeInsee'));
            }
        });
    }
};

App.Shared.Transport.View = class {
    constructor(params) {
        this.paths = params.paths;
        this.libelles = params.libelles;
        this.exportParams = params.exportParams;

        createApp({
            delimiters: ['[[', ']]'],
        }).mount('#app');

        App.Courrier.initCourrierPopover(params.courrierContent, params.courrierUrl);
        if(null !== params.facturerContent) {
            this._initFacturer(params.facturerContent);
        }
        this._initAttacherTrajet();
        App.Shared.initTrajetComplementToggle();

        App.Regulation.Trajet.initMap();
        this._initExport();
    }

    _initFacturer(content) {
        $('body').on('click', function (e) {
            if (!$(e.target).is('#facturerBtn') && $(e.target).parents('#facturerBtn').length === 0 && $(e.target).parent().length &&  $(e.target).parents('.popover.show').length === 0) {
                $('#facturerBtn').popover('hide');
            }
        });

        $('#facturerBtn').popover({
            placement: 'bottom',
            content,
            sanitize: false,
            html: true,
            title: 'Facturer le transport',
        }).on('show.bs.popover', function() {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', (event) => {
            const $form = $('#facturerForm');

            $form.change(() => {
                if($form.find(' [name="facturer_popover[mode]"]:checked').val() !== undefined && $form.find('[name="facturer_popover[type]"]:checked').val() !== undefined) {
                    $('#facturerFormBtn').prop('disabled', false);
                }
            });
        });
    }

    _initAttacherTrajet() {
        let $attacherBtn = $('#attacherBtn');

        // Comportement du popover
        $('body').on('click', function (e) {
            let $a = $(e.target);
            if ($a.parents('.air-datepicker-global-container').length === 0 && $a.parent().length !== 0 && $a.attr('id') !== 'attacherBtn' && $a.parents('#attacherBtn').length === 0 && $a.parents('.popover.show').length === 0) {
                $attacherBtn.popover('hide');
            }
        });

        $attacherBtn.popover({
            placement: 'top',
            offset: '-42%p',
            fallbackPlacement: [],
            content: '<div class="spinner bg-theme"></div>',
            sanitize: false,
            html: true,
        })
            .on('hide.bs.popover', function() {
                let $backdrop = $('.modal-backdrop').removeClass('show');
                setTimeout(() => {
                    $backdrop.remove();
                }, 150)
            })
            .on('show.bs.popover', (e) => {
                let $el = $(e.currentTarget);
                let $backdrop = $('<div class="modal-backdrop fade"></div>');
                $('body').append($backdrop);
                setTimeout(() => {
                    $backdrop.addClass('show');
                });

                let $tip = $($el.data('bs.popover').tip);
                $tip.css('min-width', '42%');
                let $content = $tip.find('.popover-body');
                let trajetAjaxRequest = null;

                let load = (url) => {
                    if(trajetAjaxRequest) {
                        trajetAjaxRequest.abort();
                        trajetAjaxRequest = null;
                    }

                    trajetAjaxRequest = $.ajax({
                        dataType: 'html',
                        url: url,
                        method: 'GET',
                        cache: false,
                        data: $content.find('.search-form').serialize() || ''
                    }).always(() => {
                    }).done((data) => {
                        let $data = $(data);
                        let interval = setInterval(() => {
                            $el.popover('update');
                        }, 10);
                        $content.html_animate($data, () => {
                            $data.find('a').each(function() {
                                if ($(this).hasClass('js-no-ajax')) {
                                    $(this).attr('target', '_blank');
                                } else if (!$(this).hasClass('js-add-trajet')) {
                                    $(this)
                                        .data('url', $(this).attr('href'))
                                        .attr('href', '#')
                                        .click(function() {
                                            $content.find('.search-form')[0].reset();
                                            load($(this).data('url'));
                                            return false;
                                        });
                                }
                            });
                            App.Layout.enableCustomInputs();
                            App.Layout.enableConfirmBtn();
                            App.Layout.enableVisibilityDelayed();
                            clearInterval(interval);

                            $data.find('.js-add-trajet').on('click', function() {
                                $el.popover('hide');
                            });
                        });
                    }).fail((e) => {
                    });
                };

                $content.on('submit', '.search-form', (e) => {
                    e.preventDefault();
                    load(this.paths.trajetAjax);
                    return false;
                });

                load(this.paths.trajetAjax);
            })
        ;
    }

    _initExport() {
        // Export (impression et téléchargement)

        $('#exportBtn').popover({
            placement: 'top',
            fallbackPlacement: [],
            content: this.exportParams.exportTemplate,
            html: true,
            sanitize: false,
            title: this.exportParams.exporterTransport
        }).on('show.bs.popover', function () {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', () => {
            App.Shared.Transport._initExport(this.exportParams.exportUrl, this.exportParams.filename);
        });

        $('body').on('click', function (e) {
            if (!$(e.target).is('#exportBtn') && $(e.target).parents('#exportBtn').length === 0 && $(e.target).parents('.popover.show').length === 0) {
                $('#exportBtn').popover('hide');
            }
        });
    }
};
